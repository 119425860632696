import React, { useState, useEffect, useRef } from 'react';
import { Container, SimpleSliderHeader, SimpleSliderLink, SimpleSliderWrapper, SimpleSliderSwiper } from './SimpleSlider.styled';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Controller, Keyboard, Autoplay } from 'swiper';
import SliderNavigation from '@/components/common/SliderNavigation/SliderNavigation';
import useIsMatches from '@/hooks/useIsMatches';
import { BaseOffsetsUI, IMetrika, LinkI, ModalBtnI } from '@/interfaces';
import { Colors } from '@/style/colors';
import { sendMetrik, clearFromNBSP } from '@/helpers';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@/redux/rootReducer';

const mapStateToProps = ({ preloader }: RootState) => ({ preloader });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const SWIPER_OPTIONS: SwiperOptions = {
    slidesPerView: 'auto',
    speed: 800,
    keyboard: true
};

interface SimpleSliderProps extends BaseOffsetsUI, IMetrika {
    className?: string;
    bg?: Colors;
    title?: string;
    links?: LinkI[];
    showNavigation?: boolean;
    border?: boolean;
    onSwiper?: (swiper: SwiperCore) => void;
    swiperOptions?: SwiperOptions;
    animate?: boolean;
    modals?: ModalBtnI[];
    id?: string;
}

SwiperCore.use([Controller, Keyboard, Autoplay]);

const SimpleSlider: React.FC<SimpleSliderProps & PropsFromRedux> = ({
    className,
    preloader,
    bg,
    title,
    links,
    showNavigation = true,
    border = false,
    onSwiper,
    children,
    swiperOptions,
    animate = true,
    topOffset,
    bottomOffset,
    modals,
    metrika,
    metriksSample,
    id
}) => {
    const refTitle = useRef<HTMLDivElement | null>(null);
    const [positionSlider, setPositionSlider] = useState('beginning');
    const [swiper, setSwiper] = useState<SwiperCore>();
    const isMobile = useIsMatches('xl');
    const optionsSlider: SwiperOptions = { ...SWIPER_OPTIONS, ...swiperOptions };

    useEffect(() => {
        const touchMove = !!swiperOptions?.allowTouchMove;
        let tm: NodeJS.Timeout;

        if (swiper) {
            swiper.allowTouchMove = !isMobile ? touchMove : true;
            tm = setTimeout(() => {
                swiper.update();
            }, 1000);
        }
        return () => clearTimeout(tm);
    }, [swiper, isMobile]);

    useEffect(() => {
        if (swiper && onSwiper) onSwiper(swiper);
    }, [swiper, onSwiper]);

    useEffect(() => {
        if (preloader.done) swiper?.update();
    }, [preloader.done]);

    return (
        <Container id={id} className={className} bg={bg} topOffset={topOffset} bottomOffset={bottomOffset}>
            {title && (
                <SimpleSliderHeader
                    border={border}
                    className={animate ? '' : '_prepare'}
                    hasButtons={!!links || !!modals}
                    hasNav={showNavigation}
                >
                    {title && <h2 ref={refTitle} dangerouslySetInnerHTML={{ __html: title }} />}
                    {(links || showNavigation) && (
                        <div>
                            {!!links?.length &&
                                links.map((link, i) => (
                                    <SimpleSliderLink
                                        {...link}
                                        key={i}
                                        metrika={
                                            metrika ||
                                            (metriksSample &&
                                                (() =>
                                                    sendMetrik(metriksSample.event, metriksSample.category, {
                                                        [metriksSample.action]: clearFromNBSP(link.text)
                                                    })))
                                        }
                                    />
                                ))}
                            {showNavigation && <SliderNavigation swiper={swiper} position={positionSlider} />}
                        </div>
                    )}
                    {modals && modals.map((modal, i) => <SimpleSliderLink metrika={metrika} {...modal} id={modal.id} key={i} />)}
                </SimpleSliderHeader>
            )}

            <SimpleSliderWrapper className={animate ? '' : '_prepare'}>
                <SimpleSliderSwiper
                    onSwiper={setSwiper}
                    {...optionsSlider}
                    onSlideChange={(swiper) =>
                        swiper.isBeginning
                            ? setPositionSlider('beginning')
                            : swiper.isEnd
                            ? setPositionSlider('end')
                            : setPositionSlider('')
                    }
                >
                    {children}
                </SimpleSliderSwiper>
            </SimpleSliderWrapper>
        </Container>
    );
};

export default connector(SimpleSlider);
